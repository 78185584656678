import React from "react"
import Container from "../components/container"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"

export default () => (
    <Container>
        <Helmet>
          <title>Thank You | An Exquisite Meal</title>
          <meta name="description" content="Thank you for subscribing." />
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        
        <div class="sub-container-page">
            <div class="body-content">
                <a href="/"><img src="../AnExquisiteMeal_headline.png" width="600" alt="An Exquisite Meal" /></a>
                <h2 class="headline">Thank you.</h2>
                <div class="description">
                    <h3>You will now receive updates about the film.</h3>
                    <p>We're on <a href="https://www.facebook.com/AnExquisiteMeal/">Facebook</a> and <a href="https://www.instagram.com/anexquisitemeal/">Instagram</a> too, if you're into that sort of thing.</p>
                </div>
            </div>        
        </div>
        
        <Footer>
        </Footer>
    </Container> 
    
)


